/* eslint-disable */
(function ($) {
    $.fn.formvalidate = function(props) {
        $(this).on('submit', function (e) {
            var valid = true;
            $(this).find("input[type=text], input[type=password]").each(function() {
                if (!$(this).hasClass('dont-validate')) {
                    var type;
                    if (!(type = $(this).data('validate'))) {
                        type = 'empty';
                    }
                    valid &= $(this).vipsitevalidate(type);
                }
            });
            if (!valid) {
                e.preventDefault();
            }
        });
    };
}(jQuery));


/*! Vip input validation
 ========================================================================== */
(function ($) {
    $.fn.vipsitevalidate = function(type) {
        // Get value to validate
        var inputVal = $(this).val();

        // Assume at start everything is alright
        var valid = true;

        // Choose filter
        switch(type) {
            case 'agree':
                $(this).parent().parent().find('.inputError').remove();
                if (!$(this).is(':checked')) {
                    $(this).parent().parent().append('<p class="inputError">'
                        + LC['AGREE_ERROR']
                        + '</p>');
                    valid = false;
                }
                break;
            case 'msisdn':
                var regexCall= /^(\+?(3816|38177)|06|077)[0-9]*$/;
                var regexFull = /^(\+?(3816[0-9]|38177))([0-9]{6,7})$/;
                var regexSimple = /^(06[0-9]|077)([0-9]{6,7})$/;
                $(this).parent().parent().find('.inputError').remove();
                if (regexCall.test(inputVal) && inputVal.length > 13) {
                    $(this).parent().parent().append('<p class="inputError">'
                        + LC['MSISDN_LENGTH_ERROR']
                        + '</p>');
                    valid = false;
                } else if (!regexFull.test(inputVal) && !regexSimple.test(inputVal)) {
                    $(this).parent().parent().append('<p class="inputError">'
                        + LC['MSISDN_ERROR']
                        + '</p>');
                    valid = false;
                }
                break;
            case 'email':
                $(this).parent().parent().find('.inputError').remove();
                var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!regex.test(inputVal)) {
                    $(this).parent().parent().append('<p class="inputError">'
                        + LC['EMAIL_ERROR']
                        + '</p>');
                    valid = false;
                }
                break;
            case 'empty':
                $(this).parent().parent().find('.inputError').remove();
                if (inputVal === '') {
                    $(this).parent().parent().append('<p class="inputError">'
                        + LC['EMPTY_ERROR']
                        + '</p>');
                    valid = false;
                }
                break;
            case 'password':
                $(this).parent().parent().find('.inputError').remove();
                var regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g;
                if (!regex.test(inputVal)) {
                    $(this).parent().parent().append('<p class="inputError">'
                        + LC['PASSWORD_ERROR']
                        + '</p>');
                    valid = false;
                }
                break;
        }

        if (!valid) {
            $(this).addClass('faultyInput');
            $(this).on('input', function() {
                $(this).removeClass('faultyInput');
            });
        }
        return valid;
    };
}(jQuery));

(function ($) {
    $.fn.vipsitevalidateMsisdn = function() {
        // Get value to validate
        var inputVal = $(this).val();

        // Assume at start everything is alright
        var valid = true;

        var regexCall= /^(\+?(3816|38177)|06|077)[0-9]*$/;
        var regexFull = /^(\+?(3816[0-9]|38177))([0-9]{6,7})$/;
        var regexSimple = /^(06[0-9]|077)([0-9]{6,7})$/;
        $(this).parent().parent().find('.inputError').remove();
        if (regexCall.test(inputVal) && inputVal.length > 13) {
            $(this).parent().parent().append('<p class="inputError">'
                + LC['LEAD_FORM_MSISDN_ERROR']
                + '</p>');
            valid = false;
        } else if (!regexFull.test(inputVal) && !regexSimple.test(inputVal)) {
            $(this).parent().parent().append('<p class="inputError">'
                + LC['LEAD_FORM_MSISDN_ERROR']
                + '</p>');
            valid = false;
        }
        if (!valid) {
            $(this).addClass('faultyInput');
            $(this).on('input', function() {
                $(this).removeClass('faultyInput');
            });
        }
        return valid;
    };
}(jQuery));